<template>
  <div
    class="border-grays-light text-grays-darkest mt-2 pb-6 text-center text-sm"
  >
    By continuing you agree to our
    <nuxt-link class="underline" target="_blank" to="/terms-of-use/employee"
      >Terms of Use</nuxt-link
    >
    and
    <nuxt-link
      class="underline"
      target="_blank"
      to="https://www.zennioptical.com/privacy-policy"
      >Privacy Policy</nuxt-link
    >. Data rates may apply.
  </div>
</template>
